


























































































































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import {
  findUserInfo,
  exportExcel,
  uploadExcel,
  conditionQuery,
  del_userInfo,
  update_userInfo,
  expirationReminder_userInfo,
} from "../../api/index";
import { saveAs } from "file-saver";

@Component({
  name: "MyUser",
  // 子组件
  components: {},
  mounted() {
    (this as any).findUseInfo();
  },
})
export default class MyUser extends Vue {
  // 上传文件的内容
  private uploadExcelUrl = "https://www.newinfinite.top/api/uploadExcel";
  // 上传文件的数据
  private uploadInfo = { user_id: sessionStorage.getItem("user_id") };
  private findFormData: any = {
    user_number: "",
    adobe_id: "",
    wechat: "",
    expire_time: [],
  };
  private tableData: any = [];
  private userInfoData: any = {
    到期时间: "",
  };
  // 默认不显示更新 form
  isShowUpdateForm = false;
  // > 分页
  paginationObj = {
    // 当前页
    currentPage: 1,
    // 全部页数
    totalNumber: 290,
    pageSizes: [20, 30, 50, 70],
    pageSize: 20,
    // 只有一页时是否隐藏
    isShowPagination: false,
  };
  // > 分页功能: 每页多少条
  handleSizeChange(val: any) {
    // 修改每页显示的数据条数
    this.paginationObj.pageSize = val;
    this.findUseInfo();
  }
  // > 分页功能: 当前第几页
  handleCurrentChange(val: any) {
    // 修改当前页数
    this.paginationObj.currentPage = val;
    this.findUseInfo();
  }
  @Ref() readonly UserFindRef!: ElForm;

  // > 发送网络请求, 查询用户信息
  private async findUseInfo() {
    // 配置请求参数
    let findRule: any = {
      user_id: sessionStorage.getItem("user_id"),
      page_No: this.paginationObj.currentPage,
      find_size: this.paginationObj.pageSize,
    };
    let res: any;
    if (
      this.findFormData.user_number ||
      this.findFormData.adobe_id ||
      this.findFormData.wechat ||
      this.findFormData.expire_time.length
    ) {
      findRule["rule"] = this.findFormData;
      // 发送网络请求
      res = await conditionQuery(findRule);
    } else {
      // 发送网络请求
      res = await findUserInfo(findRule);
    }
    // 处理结果
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.paginationObj.totalNumber = res.data.db_length;
      const newData = res.data.db_data;
      for (let i = 0; i < newData.length; i++) {
        const item = newData[i];
        item.到期时间 = new Date(item.到期时间).toLocaleDateString();
        newData[i] = item;
      }
      this.tableData = newData;
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // > 点击查询按钮
  private async submitForm() {
    // 判断 4个必须有一个条件
    try {
      this.paginationObj.currentPage = 1;
      this.findUseInfo();
    } catch (error) {
      (this as any).$message.error(error);
    }
  }
  // > 重置查询条件
  private resetForm() {
    this.UserFindRef.resetFields();
  }
  // > 上传之前进行判断
  private beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      (this as any).$message.error("上传文件不能超过 5MB!");
      return false;
    }
    return true;
  }
  // > 上传数据文件成功回调
  private async uploadSuccess(res: any) {
    // const res: any = await uploadExcel({
    //   user_id: sessionStorage.getItem("user_id"),
    // });
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // > 导出全部的数据
  private async touchExportExcel() {
    try {
      const res: any = await exportExcel({
        user_id: sessionStorage.getItem("user_id"),
      });
      if (res.code == 200) {
        (this as any).$message.success(res.msg);
        const link = document.createElement("a");
        link.download = res.data.url.split("/").pop();
        link.style.display = "none";
        link.href = res.data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        (this as any).$message.error(res.msg);
      }
    } catch (error) {
      (this as any).$message.error(error);
    }
  }
  // > 到期提醒
  private async expirationReminder(index: any, row: any) {
    const res: any = await expirationReminder_userInfo({
      user_id: sessionStorage.getItem("user_id"),
      id: row._id,
      adobe_id: row["Adobe ID"],
      phone: row["手机号"],
    });
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
    } else {
      (this as any).$message.error(res.msg);
    }
  }

  // > 删除指定数据
  private delUserInfo(index: any, row: any) {
    (this as any).$MessageBox
      .confirm("此操作将永久删该条信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        const res: any = await del_userInfo({
          user_id: sessionStorage.getItem("user_id"),
          id: row._id,
          adobe_id: row["Adobe ID"],
        });
        if (res.code == 200) {
          (this as any).$message.success(res.msg);
          this.tableData.splice(index, 1);
        } else {
          (this as any).$message.error(res.msg);
        }
      })
      .catch(() => {
        (this as any).$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  // > 点击更新
  private async handleClick(index: any, row: any) {
    const new_row = JSON.parse(JSON.stringify(row));
    new_row.到期时间 = new Date(new_row.到期时间);
    // 变更信息
    this.userInfoData = new_row;
    this.userInfoData.index = index;
    this.isShowUpdateForm = true;
  }
  // >跳转积分详情或订单详情
  private async handleEdit(index: any, row: any, purpose: any) {
    this.$router.push({
      path: "/" + purpose,
      query: { adobe_id: row["Adobe ID"] },
    });
  }
  // > 上传更新的最新的新
  private async uploadNewInfo(isShow: any) {
    if (isShow) {
      const index = this.userInfoData.index;
      // 删除多余信息
      delete this.userInfoData.index;
      const newData = {
        user_id: sessionStorage.getItem("user_id"),
        id: this.userInfoData._id,
        用户编号: this.userInfoData.用户编号,
        微信: this.userInfoData.微信,
        手机号: this.userInfoData.手机号,
        当前计划: this.userInfoData.当前计划,
        到期时间: this.userInfoData.到期时间,
        积分: this.userInfoData.积分,
      };
      if (this.userInfoData) {
        const res: any = await update_userInfo(newData);
        if (res.code == 200) {
          (this as any).$message.success(res.msg);
          this.userInfoData.到期时间 = new Date(this.userInfoData.到期时间).toLocaleDateString();
          this.$set(this.tableData, index, this.userInfoData);
          this.isShowUpdateForm = false;
        } else {
          this.isShowUpdateForm = false;
          (this as any).$message.error(res.msg);
        }
      }
    } else {
      this.isShowUpdateForm = isShow;
    }
  }
}
