












































































































































import { Component, Vue } from "vue-property-decorator";
import {
  find_integral,
  add_integral,
  del_integral,
  update_integral,
} from "../../../api/index";
@Component({
  name: "Integral",
  // 子组件
  components: {},
  // 进入页面即请求一次数据
  async mounted() {
    if (this.$route.query.adobe_id) {
      (this as any).adobe_id = this.$route.query.adobe_id;
      (this as any).findInfo();
    } else {
      this.$router.go(-1);
    }
  },
})
export default class Integral extends Vue {
  private adobe_id = "";
  private tableData = [];
  private userInfoData:any = {};
  private userInfoData2 = {
    user_id: "",
    "Adobe ID": "",
    剩余积分: 0,
    积分变化: "",
    变化时间: "",
    变化原因: "",
  };
  private addOrUpdate = {
    current: "提交新增",
    add: "提交新增",
    update: "提交更新",
  };
  // > 分页
  paginationObj = {
    // 当前页
    currentPage: 1,
    // 全部页数
    totalNumber: 0,
    pageSizes: [30, 50, 70],
    pageSize: 30,
    // 只有一页时是否隐藏
    isShowPagination: true,
  };
  // 默认不显示更新 form
  isShowUpdateForm = false;
  // > 分页功能: 每页多少条
  handleSizeChange(val:any) {
    // 修改每页显示的数据条数
    this.paginationObj.pageSize = val;
    this.findInfo();
  }
  // > 分页功能: 当前第几页
  handleCurrentChange(val:any) {
    // 修改当前页数
    this.paginationObj.currentPage = val;
    this.findInfo();
  }
  // > 发送网络请求, 查询用户信息
  private async findInfo() {
    // 配置请求参数
    const newData = {
      user_id: sessionStorage.getItem("user_id"),
      adobe_id: this.adobe_id,
      find_size: this.paginationObj.pageSize,
      page_No: this.paginationObj.currentPage,
    };
    const res:any = await find_integral(newData);
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.paginationObj.totalNumber = res.data.db_length;
      this.tableData = this.processingTimeFormat(res.data.db_data);
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // > 提交新增或更新
  private async uploadNewInfo(isShow:any) {
    if (isShow) {
      this.userInfoData.user_id = sessionStorage.getItem("user_id");
      let res:any;
      let newData:any;
      if (this.addOrUpdate.current == "提交新增") {
        res = await add_integral(this.userInfoData);
      } else {
        newData = JSON.parse(JSON.stringify(this.userInfoData));
        res = await update_integral(newData);
      }
      if (res.code == 200) {
        (this as any).$message.success(res.msg);
        // 隐藏
        this.isShowUpdateForm = false;
        // 刷新数据
        (this as any).findInfo();
      } else {
        (this as any).$message.error(res.msg);
      }
    } else {
      this.isShowUpdateForm = isShow;
    }
  }
  // 新增
  private async addInfo() {
    this.addOrUpdate.current = this.addOrUpdate.add;
    this.userInfoData = JSON.parse(JSON.stringify(this.userInfoData2));
    this.userInfoData["Adobe ID"] = this.adobe_id;
    this.isShowUpdateForm = true;
  }
  // 更新
  private async handleClick(index:any, row:any) {
    this.isShowUpdateForm = true;
    this.addOrUpdate.current = this.addOrUpdate.update;
    // 初始化
    this.userInfoData = JSON.parse(JSON.stringify(row));
    this.userInfoData["Adobe ID"] = this.adobe_id;
  }
  // 删除
  private async delUserInfo(index:any, row:any) {
    console.log(row);
    
    (this as any).$MessageBox
      .confirm("此操作将永久删除该积分信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        const res:any = await del_integral({
          user_id: sessionStorage.getItem("user_id"),
          _id: row._id,
          adobe_id:row['Adobe ID'],
        });
        if (res.code == 200) {
          (this as any).$message.success(res.msg);
          this.tableData.splice(index, 1);
        } else {
          (this as any).$message.error(res.msg);
        }
      })
      .catch(() => {
        (this as any).$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }

  // 处理时间显示的问题
  private processingTimeFormat(db_data:any) {
    const newData = db_data;
    for (let i = 0; i < newData.length; i++) {
      const item = newData[i];
      delete item.__v;
      item.变化时间 = new Date(item.变化时间).toLocaleDateString();
      newData[i] = item;
    }
    return newData;
  }
}
