























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { findAll_picture } from "../../src/api/index";

@Component({
  name: "J_HomeItem",
  // 子组件
  components: {},
})
export default class J_HomeItem extends Vue {
  private UploadImgUrl = "https://www.newinfinite.top/api/update/picture";
  @Prop() uploadData!: any;
  isShowNetWorkUrl = false;
  netWorkUrl = "";
  // 上传用的信息
  uploadInfo = {
    user_id: sessionStorage.getItem("user_id"),
    home_item: this.uploadData.home_item,
  };
  // 上传之前进行判断
  private beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      (this as any).$message.error("上传头像图片大小不能超过 2MB!");
      return false;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      return true;
    } else {
      (this as any).$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      return false;
    }
  }
  // 强制更新页面
  reload() {
    (this as any).isReloadData = false;
    this.$nextTick(() => {
      (this as any).isReloadData = true;
    });
  }
  // 上传
  private uploadSuccess(res: any) {
    if (res.code == 200) {
      this.netWorkUrl = res.data.home_item[this.uploadData.home_item];
      this.$store.commit("setImages", res.data);
      (this as any).$message.success(res.msg);
      this.isShowNetWorkUrl = true;
      this.reload();
    } else {
      (this as any).$message.error(res.msg);
    }
  }
}
