

































































import { Component, Vue } from "vue-property-decorator";
import { imageOrderChange } from "../../../api/index";
// el-table 拖动排序功能
import Sortable from "sortablejs";
@Component({
  name: "AdvertCarousel",
  // 子组件
  components: {},
  mounted: function () {
    (this as any).rowDrop();
    this.$nextTick(async function () {
      // 获取 vuex 中的数据
      const data: any = this.$store.getters.getImages;
      if (data.advert_carousel) {
        (this as any).tableData = data.advert_carousel;
      } else {
        (this as any).$message.warning("暂时没有图片资源,请上传图片");
      }
    });
  },
})
export default class AdvertCarousel extends Vue {
  private UploadImgUrl = "https://www.newinfinite.top/api/update/picture";
  private tableData = [];
  private isShowUploadBtn = false;
  private advertCarouselObj = {
    user_id: sessionStorage.getItem("user_id"),
    position: "advert_carousel",
  };
  // 上传之前进行判断
  private beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      (this as any).$message.error("上传头像图片大小不能超过 2MB!");
      return false;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      return true;
    } else {
      (this as any).$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      return false;
    }
  }
  // 拖动排序
  rowDrop() {
    const tbody = document.querySelector(".el-table__body-wrapper tbody");
    const _this = this;
    Sortable.create(tbody, {
      onEnd({ newIndex, oldIndex }: any) {
        _this.isShowUploadBtn = true;
        const currRow = _this.tableData.splice(oldIndex, 1)[0];
        _this.tableData.splice(newIndex, 0, currRow);
      },
    });
  }
  // 调换顺序
  async orderChange(isDel: any) {
    const newData: any = this.tableData;
    // 调换顺序
    for (let i = 0; i < newData.length; i++) {
      const item: any = newData[i];
      item.index = i;
      newData[i] = item;
    }
    // 向服务器发送新的顺序
    const res: any = await imageOrderChange({
      user_id: sessionStorage.getItem("user_id"),
      position: "advert_carousel",
      data: newData,
    });
    if (res.code == 200) {
      this.isShowUploadBtn = false;
      if (isDel) res.msg = "删除成功";
      (this as any).$message.success(res.msg);
      // 跟新数据
      this.tableData = res.data.advert_carousel;
      // 刷新页面
      this.reload();
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  //  表格删除行
  deleteRow(index: any, rows: any) {
    // 操作数据
    rows.splice(index, 1);
    // 上传最新的数组
    (this as any).orderChange("del");
  }

  // 强制更新页面
  reload() {
    (this as any).isReloadData = false;
    this.$nextTick(() => {
      (this as any).isReloadData = true;
    });
  }
  // 上传
  private uploadSuccess(res: any) {
    if (res.code == 200) {
      this.tableData = res.data.advert_carousel;
      this.$store.commit("setImages", res.data);
      (this as any).$message.success(res.msg);
      this.reload();
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // 上传出错
  private uploadError(err: any) {
    (this as any).$message.error(err);
  }
}
