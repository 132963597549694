





















import { Component, Vue } from "vue-property-decorator";
import { findAll_picture } from "../../../api/index";
@Component({
  name: "PersonalCover",
  // 子组件
  components: {},
  destroyed() {
    // 销毁监听界面刷新事件
  },
  mounted: function () {
    this.$nextTick(async function () {
      (this as any).pageRefresh();
    });
  },
})
export default class PersonalCover extends Vue {
  private UploadImgUrl = "https://www.newinfinite.top/api/update/picture";
  private isShowQRcode = false;
  private personal_coverUrl?: "";
  private advertCarouselObj = {
    user_id: sessionStorage.getItem("user_id"),
    position: "personal_cover",
  };
  async pageRefresh() {
    // 获取 vuex 中的数据
    const data = this.$store.getters.getImages;
    if (data.personal_cover) {
      this.personal_coverUrl = data.personal_cover;
      this.isShowQRcode = true;
    } else {
      // 查询图片数据库
      const res: any = await findAll_picture({
        user_id: sessionStorage.getItem("user_id"),
      });
      if (res.code == 200) {
        this.personal_coverUrl = data.personal_cover;
        this.$store.commit("setImages", res.data);
        this.isShowQRcode = true;
        this.reload();
      } else {
        (this as any).$message.warning("暂时没有图片资源,请上传图片");
      }
    }
  }
  // 上传之前进行判断
  private beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      (this as any).$message.error("上传头像图片大小不能超过 2MB!");
      return false;
    }
    if (file.type === "image/png" || file.type === "image/jpeg") {
      return true;
    } else {
      (this as any).$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      return false;
    }
  }
  // 强制更新页面
  reload() {
    (this as any).isReloadData = false;
    this.$nextTick(() => {
      (this as any).isReloadData = true;
    });
  }
  // 上传
  private uploadSuccess(res: any) {
    if (res.code == 200) {
      this.personal_coverUrl = res.data.personal_cover;
      this.$store.commit("setImages", res.data);
      (this as any).$message.success(res.msg);
      this.isShowQRcode = true;
      this.reload();
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // 上传出错
  private uploadError(err: any) {
    (this as any).$message.error(err);
  }
}
