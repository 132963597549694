










































































import { Component, Vue, Ref } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import {
  find_downloadLink,
  add_downloadLink,
  del_downloadLink,
} from "../../api/index";

@Component({
  name: "Upload",
  // 子组件
  components: {},
  async mounted() {
    (this as any).findDownloadLink();
  },
})
export default class Upload extends Vue {
  private tableData = [];
  private downloadLinkForm = {
    user_id: "",
    name: "",
    windows: "",
    mac: "",
    current: "windows",
  };
  @Ref() readonly downloadLinkRef!: ElForm;
  // > 查询全部的下载链接
  private async findDownloadLink() {
    const id = sessionStorage.getItem("user_id");
    const res: any = await find_downloadLink({
      user_id: id ? id : "",
    });
    if (res.code == 200) {
      this.tableData = res.data;
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // > 新增一个下载链接
  private async submitForm() {
    const id = sessionStorage.getItem("user_id");
    this.downloadLinkForm.user_id = id ? id : "";
    const res: any = await add_downloadLink(this.downloadLinkForm);
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.findDownloadLink();
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // > 删除一个下载链接
  private async delDownloadLink(index: any, row: any) {
    const newObj = {
      user_id: sessionStorage.getItem("user_id"),
      id: row._id,
    };
    const res: any = await del_downloadLink(newObj);
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.findDownloadLink();
    } else {
      (this as any).$message.error(res.msg);
    }
  }

  // > 重置 form
  private resetForm() {
    this.downloadLinkRef.resetFields();
  }
}
