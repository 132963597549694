













































































import { Component, Vue } from "vue-property-decorator";
import { find_pending, read_pending } from "../../api/index";
@Component({
  name: "NewNews",
  // 子组件
  components: {},
  mounted() {
    (this as any).findPending();
  },
})
export default class NewNews extends Vue {
  private tableData: any = []; // 列表数据
  // > 分页
  paginationObj = {
    // 当前页
    currentPage: 1,
    // 全部页数
    totalNumber: 0,
    pageSizes: [20, 30, 50, 70],
    pageSize: 20,
    // 只有一页时是否隐藏
    isShowPagination: false,
  };
  // > 分页功能: 每页多少条
  handleSizeChange(val: any) {
    // 修改每页显示的数据条数
    this.paginationObj.pageSize = val;
    this.findPending();
  }
  // > 分页功能: 当前第几页
  handleCurrentChange(val: any) {
    // 修改当前页数
    this.paginationObj.currentPage = val;
    this.findPending();
  }
  // > 获得续费提醒数据
  async findPending() {
    const findRule: any = {
      user_id: sessionStorage.getItem("user_id"),
      page_No: this.paginationObj.currentPage,
      find_size: this.paginationObj.pageSize,
    };
    const res:any = await find_pending(findRule);
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.paginationObj.totalNumber = res.data.db_length;
      const newData = res.data.db_data;
      for (let i = 0; i < newData.length; i++) {
        const item = newData[i];
        item.下单时间 = new Date(item.下单时间).toLocaleDateString();
        newData[i] = item;
      }
      this.tableData = newData;
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // > 处理数据
  async touchHandle(index: any, row: any) {
    const rule = {
      user_id: sessionStorage.getItem("user_id"),
      data_id: row._id,
    };
    const res:any = await read_pending(rule);
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.tableData.splice(index, 1);
    } else {
      (this as any).$message.error(res.msg);
    }
  }
}
