



















































import { Component, Vue } from "vue-property-decorator";
import { findAll_picture } from "../../../api/index";
import J_HomeItem from "../../../components/J_HomeItem.vue";
@Component({
  name: "HomeItem",
  // 子组件
  components: {
    J_HomeItem,
  },
  mounted() {
    const images = this.$store.getters.getImages;
    if (images.home_item) {
      const keys = Object.keys(images.home_item);
      keys.forEach((key) => {
        if (key === "Renew") {
          (this as any).renewData.url = images.home_item[key];
        } else if (key === "recommend") {
          (this as any).recommendData.url = images.home_item[key];
        } else if (key === "share") {
          (this as any).shareData.url = images.home_item[key];
        } else if (key === "download") {
          (this as any).downloadData.url = images.home_item[key];
        } else if (key === "help") {
          (this as any).helpData.url = images.home_item[key];
        }
      });
    }
  },
})
export default class HomeItem extends Vue {
  private renewData: Object = {
    home_item: "Renew",
    title: "续费/订阅",
    url: require("../../../assets/image/zhanwei.png"),
  };
  private recommendData: Object = {
    home_item: "recommend",
    title: "二维码海报",
    url: require("../../../assets/image/zhanwei.png"),
  };
  private shareData: Object = {
    home_item: "share",
    title: "好友分享",
    smallSize: true,
    url: require("../../../assets/image/zhanwei.png"),
  };
  private downloadData: Object = {
    home_item: "download",
    title: "百度云连接",
    url: require("../../../assets/image/zhanwei.png"),
  };
  private helpData: Object = {
    home_item: "help",
    title: "技术支持",
    url: require("../../../assets/image/zhanwei.png"),
  };
}
