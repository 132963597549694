







































































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import {
  find_orderInfo,
  add_orderInfo,
  del_orderInfo,
  update_orderInfo,
  add_integral,
} from "../../../api/index";

@Component({
  name: "Order",
  // 子组件
  components: {},
  async mounted() {
    if (this.$route.query.adobe_id) {
      (this as any).adobe_id = this.$route.query.adobe_id;
      (this as any).findInfo();
    } else {
      this.$router.go(-1);
    }
  },
})
export default class Order extends Vue {
  private adobe_id = "";
  private tableData = [];
  private userInfoData: any = {};
  private userInfoData2 = {
    user_id: "",
    "Adobe ID": "",
    商品名称: "",
    订单编号: "",
    充值时间: "",
    激活时间: "",
    金额: "",
    数量: 1,
    备注: "",
  };
  private addOrUpdate = {
    current: "提交新增",
    add: "提交新增",
    update: "提交更新",
  };

  // > 分页
  paginationObj = {
    // 当前页
    currentPage: 1,
    // 全部页数
    totalNumber: 0,
    pageSizes: [30, 50, 70],
    pageSize: 30,
    // 只有一页时是否隐藏
    isShowPagination: true,
  };
  // 默认不显示更新 form
  isShowUpdateForm = false;
  // > 分页功能: 每页多少条
  handleSizeChange(val: any) {
    // 修改每页显示的数据条数
    this.paginationObj.pageSize = val;
    (this as any).findInfo();
  }
  // > 分页功能: 当前第几页
  handleCurrentChange(val: any) {
    // 修改当前页数
    this.paginationObj.currentPage = val;
    (this as any).findInfo();
  }
  // > 发送网络请求, 查询用户信息
  private async findInfo() {
    // 配置请求参数
    const newData = {
      user_id: sessionStorage.getItem("user_id"),
      adobe_id: this.adobe_id,
      find_size: this.paginationObj.pageSize,
      page_No: this.paginationObj.currentPage,
    };
    // 发送网络请求
    const res: any = await find_orderInfo(newData);
    // 处理结果
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.paginationObj.totalNumber = res.data.db_length;
      this.tableData = this.processingTimeFormat(res.data.db_data);
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // 点击提交或取消按钮
  private async uploadNewInfo(isShow: any) {
    if (isShow) {
      if (
        !this.userInfoData.商品名称 ||
        !this.userInfoData.充值时间 ||
        !this.userInfoData.激活时间 ||
        (!this.userInfoData.金额 && this.userInfoData.金额 !== 0) ||
        !this.userInfoData.数量
      ) {
        (this as any).$message.error("除去订单编号外其他都是必填项");
      } else {
        this.userInfoData.user_id = sessionStorage.getItem("user_id");
        let res: any;
        let newData: any;
        newData = JSON.parse(JSON.stringify(this.userInfoData));
        newData.金额 = parseFloat(newData.金额);
        if (this.addOrUpdate.current == "提交新增") {
          res = await add_orderInfo(newData);
        } else {
          delete newData["Adobe ID"];
          res = await update_orderInfo(newData);
        }

        if (res.code == 200) {
          (this as any).$message.success(res.msg);
          // 隐藏
          this.isShowUpdateForm = false;
          // 重置数据

          // 刷新数据
          this.findInfo();
        } else {
          (this as any).$message.error(res.msg);
        }
      }
    } else {
      //点击取消 隐藏 form
      this.isShowUpdateForm = isShow;
    }
  }
  // 新增
  private async addInfo() {
    this.isShowUpdateForm = true;
    this.addOrUpdate.current = this.addOrUpdate.add;
    // 初始化
    this.userInfoData = JSON.parse(JSON.stringify(this.userInfoData2));
    this.userInfoData["Adobe ID"] = this.adobe_id;
  }
  // 更新
  private async handleClick(index: any, row: any) {
    this.isShowUpdateForm = true;
    this.addOrUpdate.current = this.addOrUpdate.update;
    // 初始化
    this.userInfoData = JSON.parse(JSON.stringify(row));
    this.userInfoData["Adobe ID"] = this.adobe_id;
  }
  // 删除
  private async delUserInfo(index: any, row: any) {
    (this as any).$MessageBox
      .confirm("此操作将永久删除该订单信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        const res: any = await del_orderInfo({
          user_id: sessionStorage.getItem("user_id"),
          _id: row._id,
        });
        if (res.code == 200) {
          (this as any).$message.success(res.msg);
          this.tableData.splice(index, 1);
        } else {
          (this as any).$message.error(res.msg);
        }
      })
      .catch(() => {
        (this as any).$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  // 处理时间显示的问题
  private processingTimeFormat(db_data: any) {
    const newData = db_data;
    for (let i = 0; i < newData.length; i++) {
      const item = newData[i];
      delete item.__v;
      item.充值时间 = new Date(item.充值时间).toLocaleDateString();
      item.激活时间 = new Date(item.激活时间).toLocaleDateString();
      newData[i] = item;
    }
    return newData;
  }
}
