






















import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "Picture",
  // 子组件
  components: {},
})
export default class Picture extends Vue {
  private menuData = [
    {
      id: 1,
      itemName: "首页轮播图",
      path: "/homeCarousel",
    },
    {
      id: 2,
      itemName: "首页分类模板",
      path: "/homeItem",
    },
    {
      id: 3,
      itemName: "二维码分享海报",
      path: "/QRcode",
    },
    {
      id: 4,
      itemName: "最新播报轮播图",
      path: "/advertCarousel",
    },
    { id: 5, itemName: "个人封面图", path: "/personalCover" },
  ];

  handleSelect(key: any) {
    const index = key.split("-");
    const item = this.menuData[index[0] - 1];
    this.$router.push(item.path);
  }
}
