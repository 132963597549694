







































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import { add_userInfo } from "../../api/index";
@Component({
  name: "AddUser",
  // 子组件
  components: {},
})
export default class AddUser extends Vue {
  userInfoData = {
    user_id: sessionStorage.getItem('user_id'),
    user_number: "",
    adobe_id: "", //
    wechat: "", // 微信号
    phoneNumber: "", // 微信号
    name: "", //当前计划或商品名称
    expire_time: "", //到期时间
    recharge_time: "", // 充值时间
    activation_time: "", // 激活时间
    number: "", // 订单编号
    price: '0', //金额
    count: 1, // 数量
    surplus: 0, // 剩余积分
    change: "", // 积分使用
    reason: "", // 积分变化原因
    change_time: "", // 积分变化时间
  };
  @Ref() readonly userInfoFormRef!: ElForm;
  // 保存信息按钮
  async onSubmit() {
    try {
      (this as any).userInfoData.price = parseFloat(this.userInfoData.price);
      const res:any = await add_userInfo(this.userInfoData)
      if (res.code == 200) {
        (this as any).$message.success(res.msg)
        // 清空数据
        this.userInfoFormRef.resetFields();
      } else {
        (this as any).$message.error(res.msg)
      }
    } catch (error) {
      (this as any).$message.error(error)
    }
  }
}
