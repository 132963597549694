










































import { Component, Vue } from "vue-property-decorator";
import { signOut } from "../api/index";
@Component({
  name: "V_Home",
  // 子组件
  components: {},
})
export default class Home extends Vue {
  // 左侧菜单数据
  private menuData = [
    {
      id: 1,
      icon: "el-icon-user",
      itemName: "用户管理",
      child: [
        { id: 1, icon: "abc", itemName: "我的用户", path: "/myUser" },
        { id: 2, icon: "abc", itemName: "添加用户", path: "/addUser" },
      ],
    },
    {
      id: 2,
      icon: "el-icon-document-copy",
      itemName: "新增订单",
      path: "/newNews",
    },
    {
      id: 3,
      icon: "el-icon-picture-outline",
      itemName: "图片管理",
      path: "/picture",
    },
    { id: 4, icon: "el-icon-receiving", itemName: "下载链接", path: "/upload" },
    { id: 5, icon: "el-icon-bank-card", itemName: "价格管理", path: "/price" },
  ];

  private selectMenuItem(key: any) {
    const index = key.split("-");
    const item = this.menuData[index[0] - 1];
    const path = item.child ? item.child[index[1] - 1].path : item.path;
    this.$router.push(path);
  }
  // ! 退出
  private signOut() {
    signOut({ user_id: this.$store.getters.user_id })
      .then((result: any) => {
        if (result.code == 200) {
          sessionStorage.setItem("user_id", "");
          this.$router.push("/");
        } else {
          (this as any).$message(result.msg);
        }
      })
      .catch((error) => {});
    // 2.返回登录界面
  }
}
