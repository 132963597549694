































import { Component, Vue, Ref } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import { find_price, update_price } from "../../api/index";

@Component({
  name: "Price",
  // 子组件
  components: {},
  async mounted() {
    (this as any).findPrices();
  },
})
export default class Price extends Vue {
  private priceForm:any = {
    year: "",
    season: "",
    moon: "",
  };
  @Ref() readonly priceFef!: ElForm;
  // > 获取最新价格
  private async findPrices() {
    const id = sessionStorage.getItem("user_id");
    const res: any = await find_price({
      user_id: id ? id : "",
    });
    if (res.code == 200) {
      this.priceForm = res.data;
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // >
  private async submitForm() {
    const id = sessionStorage.getItem("user_id");
    this.priceForm.user_id = id ? id : "";
    const res: any = await update_price(this.priceForm);
    if (res.code == 200) {
      (this as any).$message.success(res.msg);
      this.findPrices();
    } else {
      (this as any).$message.error(res.msg);
    }
  }
  // > 重置 form
  private resetForm() {
    this.priceFef.resetFields();
  }
}
